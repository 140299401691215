export default async function getConfig(sdkKey: string, user_id: string): Promise<Response> {
    const params = new URLSearchParams({
        sdkKey,
        user_id,
        isAnonymous: 'false',
        platform: 'web',
        platformVersion: 'unknown',
        deviceModel: window.navigator.userAgent,
        sdkType: 'client',
        sdkPlatform: 'opt-in',
        sdkVersion: '1.0.0',
        createdDate: new Date().toString(),
        lastSeenDate: new Date().toString()
    }).toString()
    const resp = await fetch(`https://sdk-api.devcycle.com/v1/optInConfig?${params}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (!resp.ok) {
        throw new Error('Failed to fetch config')
    }
    return resp
}

