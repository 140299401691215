import FeatureToggle from "./FeatureToggle"
import SaveButton from "./SaveButton"
import { Controller, useForm } from "react-hook-form"
import updateOptIn from "./updateOptIn"
import FeedbackAlert, {
  VARIANT as FEEDBACK_ALERT_VARIANT,
} from "./components/FeedbackAlert";
import { useEffect, useState } from "react";
import Spinner from "./components/Spinner/Spinner";

export type formValues = {
  [id: string]: boolean
}

type optInFeature = {
  id: string,
  status: boolean
  name: string
  description: string
}

type FeatureToggleListProps = {
  isPreview: boolean
  config: {
    features?: optInFeature[]
    settings: {
      enabled?: boolean
      imageURL?: string
      title?: string
      description?: string
      colors?: {
        primary?: string
        secondary?: string
      }
    }
  }
  userId: string
  projectId: string
  error: string
  setError: (error: string) => void
  onLogoLoad: () => void
  onLogoError: () => void
  isFetchingConfig: boolean
}
export default function FeatureToggleList({ isPreview, config, userId, projectId, error, setError, onLogoError, onLogoLoad, isFetchingConfig }: FeatureToggleListProps) {
  const features: optInFeature[] = config.features || []

  const [isFeedbackShown, setFeedbackShown] = useState(false)

  const formVals = features.reduce((result: Record<string, boolean>, feature) => {
    result[feature.id] = feature.status
    return result
  }, {})

  const { control, handleSubmit, formState: { isDirty, isSubmitted }, getValues, reset } = useForm<formValues>({
    defaultValues: formVals
  })

  useEffect(() => {
    if (isSubmitted) {
      setFeedbackShown(true)
      reset(getValues())
    }
  }, [isSubmitted, getValues, reset])

  const submitForm = (data: formValues) => {
    setFeedbackShown(false)
    setError('')
    void updateOptIn(data).then((r) => {
      if (r.ok) {
        window.parent.postMessage({ type: "DVC.optIn.saved" }, "*")
      } else {
        setError('Your changes failed to save')
      }
    })
  }

  const optInSettings = config.settings
  const logoSource = optInSettings?.imageURL || ''
  const headerText = optInSettings?.title || 'Feature Opt-In Program Header'
  const descriptionText = optInSettings?.description || 'Description dummy text. We\'ve got a few beta features that we\'re currently testing. If you\'d like to be a part of our beta program, you can opt-in to any of the features listed below to see the best of what the future has in store. Not interested? That\'s not a problem - just leave all of the toggles in the off position.'
  const nofeaturesText = `We checked high and low, but unfortunately there are currently no features available for opt-in.`

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      {isFetchingConfig && !isPreview ? 
        <Spinner />
      : (
      <>
        <div className="mt-6 max-w-screen-lg mx-auto px-5 md:px-10">
          {logoSource && (
            <>
              <div className="col-span-1 flex justify-center py-8">
                <img alt={"Feature Opt-In"} src={logoSource} onLoad={onLogoLoad} onError={onLogoError}/>
              </div>{" "}
              <hr className="w-50" />
            </>
          )}
          <div className="mt-3 text-left break-words">
            <h2 className="text-lg font-medium text-gray-900 py-2">
              {headerText}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              {descriptionText}
            </p>
          </div>
          <div className="py-5">
            {features.length < 1 && !isFetchingConfig && (
              <div className="bg-blue-50 text-blue-800 rounded-lg my-6">
                <p className='font-medium text-center py-6 px-5'>{nofeaturesText}</p>
              </div>
            )}
            {features.length > 0 && 
              <ul className="border-gray-200 divide-y divide-gray-200 py-5 pr-2">
                {
                  features.map((feature) => {
                    return (
                      <Controller
                        key={feature.id}
                        control={control}
                        name={feature.id}
                        defaultValue={feature.status}
                        render={({
                          field: { onChange, value, name, ref },
                        }) => (
                          <FeatureToggle
                            name={feature.name}
                            description={feature.description}
                            isEnabled={value}
                            onChange={(isEnabled: boolean) => onChange(isEnabled)} />
                        )}
                      />
                    )
                  })
                }
                </ul>
              }
          </div>
        </div>

        <div className="bg-gray-50 rounded-b-lg">
          {features?.length > 0 && (
            <div className="max-w-screen-lg mx-auto px-1 sm:px-10">
              <SaveButton formIsDirty={isDirty} isPreview={isPreview}/>
            </div>
          )}
          {(isFeedbackShown && !error) && (
            <FeedbackAlert
              message={'All Changes Saved'}
              variant={FEEDBACK_ALERT_VARIANT.success}
            />
          )}
        </div>
      </>
    )}
    </form>
  )
}
